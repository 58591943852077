<template>
  <div class="examples">
    <span class="page-heading"> Analytics </span>
    <hr class="line-break" />
    <div class="box">
      <div class="headers">
        <h1 class="filter-text">Filter Analytics</h1>
      </div>
      <span class="date-text"> Date Range </span>
      <PassportRadio value="Overall" v-model="dateOption" />
      <div :class="[{ container: dateOption !== 'Select Date Range' }]">
        <PassportRadio value="Select Date Range" v-model="dateOption" />
        <div v-if="dateOption === 'Select Date Range'" class="date-range container">
          <PassportDatepicker
            v-model="analyticsDate.start"
            :max-date="analyticsDate.end || new Date()"
            placeholder="Start Date"
          />
          <div class="separator">To</div>
          <PassportDatepicker
            v-model="analyticsDate.end"
            :min-date="analyticsDate.start || undefined"
            :max-date="new Date()"
            placeholder="End Date"
          />
        </div>
        <div class="button-container">
          <PassportButton variant="outline" label="Reset" @click="resetDateRange" />
          <PassportButton class="apply-button" @click="getAnalytics" label="Apply" />
        </div>
      </div>
    </div>
    <hr class="line-break" />
    <div class="sub-section">
      <div>
        <span class="results-text"> Showing results for: </span>
        <span class="date-text"> date range: {{ dateOption }} </span>
      </div>
      <PassportButton @click="downloadExcel" label="Download Excel file" />
    </div>
    <div class="stats-holder">
      <Stats
        v-for="(points, name) in tracking"
        :key="name"
        :heading="statTitle(name)"
        :points="points"
        :style="name === 'allUserScans' ? 'display: none;' : ''"
      />
    </div>
  </div>
</template>

<script>
import PassportButton from '@/components/PassportButton.vue';
import { ApiService } from '@/services/api.service';
import xlsx from 'json-as-xlsx';
import PassportRadio from '@/components/PassportRadio.vue';
import PassportDatepicker from '@/components/PassportDatepicker.vue';
import Stats from '@/components/Stats.vue';

export default {
  name: 'default',
  components: { PassportRadio, PassportButton, Stats, PassportDatepicker },
  props: {},
  data() {
    return {
      tracking: '',
      dateOption: 'Overall',
      analyticsDate: {
        start: null,
        end: null,
      },
      statName: {
        maxPointsForEvent: 'Max points possible for event',
        totalPointsEarnedByAllUsers: 'Total points earned by all users',
        totalNumberOfScans: 'Total number of scans',
        averageNumberOfPointsPerUser: 'Average number of points per user',
        averageTimeBetweenScans: 'Average time between scans',
        averageScansPerUser: 'Average scans per user',
        numberOfUsersWithAtLeastOneScan: 'Number of users with at least 1 scan',
        numberOfUsersWithMultipleScans: 'Number of users with multiple scans',
        peakUsageTime: 'Peak usage time',
      },
    };
  },
  created() {},
  computed: {},
  watch: {},
  mounted() {
    this.getAnalytics();
  },
  methods: {
    resetDateRange() {
      this.analyticsDate = {
        start: null,
        end: null,
      };
    },
    convertToTime(time) {
      let seconds = Math.floor((time / 1000) % 60);
      let minutes = Math.floor((time / (1000 * 60)) % 60);
      let hours = Math.floor((time / (1000 * 60 * 60)) % 24);

      hours = hours < 10 ? `0${hours}` : hours;
      minutes = minutes < 10 ? `0${minutes}` : minutes;
      seconds = seconds < 10 ? `0${seconds}` : seconds;

      return `${hours}:${minutes}:${seconds}`;
    },
    async getAnalytics() {
      if (this.dateOption === 'Select Date Range') {
        const { data: selectAnalytics } = await ApiService.get(
          `/tracking?start=${this.analyticsDate.start.toISOString()}&end=${this.analyticsDate.end.toISOString()}`,
        );
        selectAnalytics.averageTimeBetweenScans = this.convertToTime(selectAnalytics.averageTimeBetweenScans);
        this.tracking = selectAnalytics;
      } else {
        await ApiService.get('/tracking').then((res) => {
          res.data.averageTimeBetweenScans = this.convertToTime(res.data.averageTimeBetweenScans);
          this.tracking = res.data;
        });
      }
    },

    async downloadExcel() {
      const trackingFormatted = [];
      const trackingKeys = Object.keys(this.tracking);
      const trackingValues = Object.values(this.tracking);

      for (let i = 0; i < trackingKeys.length; i += 1) {
        const obj = {
          key: this.statTitle(trackingKeys[i]),
          value: trackingValues[i],
        };
        if (trackingKeys[i] === 'peakUsageTime') {
          obj.value = new Date(trackingValues[i]).toLocaleString('en-CA');
        }
        if (trackingKeys[i] !== 'allUserScans') {
          trackingFormatted.push(obj);
        }
      }

      const data = [
        {
          sheet: 'Overview',
          columns: [
            {
              label: 'Metric',
              value: 'key',
            },
            {
              label: 'Value',
              value: 'value',
            },
          ],
          content: trackingFormatted,
        },
        {
          sheet: 'User Scans',
          columns: [
            {
              label: 'Initials',
              value: 'username',
            },
            {
              label: 'Email',
              value: 'email',
            },
            {
              label: 'Language',
              value: 'locale',
            },
            {
              label: 'Email Opt-In?',
              value: 'optIn',
            },
            {
              label: 'Admin User?',
              value: 'admin',
            },
            {
              label: 'Date User Created',
              value: (row) => new Date(row.createdAt).toLocaleString('en-CA', { timeZoneName: 'short' }),
            },
            {
              label: '# Scans',
              value: 'numScans',
            },
            {
              label: '# Unique Scans',
              value: 'numUniqueScans',
            },
          ],
          content: this.tracking.allUserScans,
        },
      ];

      const settings = {
        fileName: `BroncoEggHunt-Analytics-${new Date().toLocaleDateString('en-CA')}`, // BroncoEggHunt-yyyy-mm-dd
        extraLength: 3, // A bigger number means that columns will be wider
      };

      return xlsx(data, settings); // Will download the excel file
    },

    statTitle(name) {
      return this.statName[name];
    },
  },
};
</script>

<style lang="scss" scoped>
.examples {
  .line-break {
    width: 1601px;
    margin-top: 3em;
    margin-bottom: 3em;
    opacity: 0.1;
  }
  .page-heading {
    display: flex;
    font-size: 28px;
    letter-spacing: -0.7px;
  }
  .example {
    padding: 10px;
    margin-bottom: 10px;
    border: 2px solid white;
    border-radius: 10px;

    ::v-deep {
      .header {
        padding: 10px;
      }
      .collapse-wrapper > .content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}
.buttons {
  display: flex;
  justify-content: flex-end;
}
::v-deep .passport-button.outline.primary,
.apply-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 40px;
  margin-right: 10px;
  font-size: 14px;
  text-transform: uppercase;
  // border-radius: 0.5em;
}
::v-deep .passport-button.default.primary {
  display: flex;
  align-items: center;
  font-size: 14px;
  text-transform: uppercase;
}
::v-deep .g-input .input-element:not([type='submit'], [type='range']) {
  width: 192px;
}

.box {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px;
  margin: auto;
  margin-bottom: 10px;
  background: #ffffff26 0% 0% no-repeat padding-box;
  border-radius: 7px;
  opacity: 1;
}
.headers {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2em;
}
.filter-text {
  font-size: 28px;
  font-weight: normal;
  letter-spacing: -0.7px;
}
::v-deep .g-radio .label.after {
  padding: 12px;
}
.container {
  display: flex;
  justify-content: space-between;
}
.date-range {
  align-items: center;
  width: 452px;
  .separator {
    margin: 25px;
    text-transform: uppercase;
  }
}
.button-container {
  display: flex;
  justify-content: flex-end;
}
.date-text {
  margin-bottom: 1em;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.6px;
}
.sub-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.results-text {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.6px;
}
.stats-holder {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2em;
}
.points-box {
  width: calc(100% / 3 - 20px);
}
</style>
