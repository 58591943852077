<template>
  <div class="points-box">
    <span class="heading">{{ heading }} </span>
    <span class="points-text"> {{ pointsText }} </span>
  </div>
</template>

<script>
export default {
  name: 'Stats',
  components: {},
  props: {
    heading: String,
    points: [Number, String],
  },
  data() {
    return {};
  },
  created() {},
  computed: {
    pointsText() {
      if (this.heading === 'Peak usage time') {
        return new Date(this.points).toLocaleString('en-CA');
      }
      return this.points;
    },
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.points-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 336px;
  padding: 16px;
  margin: auto;
  margin-top: 16px;
  background: #fff;
  border: 1px solid #222;
  border-radius: 7px;
  opacity: 1;
}
.heading {
  margin-bottom: 1.5em;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #222;
  letter-spacing: 0.6px;
}
.points-text {
  margin-bottom: 1em;
  font-size: 28px;
  color: #d9232d;
}
</style>
